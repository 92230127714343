:root {
	--primary-color: #111111;
	--secondary-color: #2f2f2f;
	--black-color: #000000;
	--white-color: #f5f5f5;
	--gray-color: #cacaca;
	--dark-gray-color: #353434;
	--text-color: #cccccc;
	--yellow-color: #eeb20f;
	--red-color: #e63625;
	--origin-red-color: #e53625;
	--blue-color: #2874fc;
	--origin-blue-color: #0d4692;
	--green-color: #046d38;
	--origin-green-color: #007338;

	--menu-bkgd-color: rgba(217, 217, 217, 0.15);

	--jakarta-font: "Plus Jakarta Sans", sans-serif;
	--roboto-font: "Roboto", sans-serif;
	--montserrat-font: "Montserrat", sans-serif;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	height: 100%;
	margin: 0 auto;
	overflow-x: hidden;
	background-color: var(--primary-color);
}

.root {
	width: 100%;
	height: 100%;
}
